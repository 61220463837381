import React, {useState} from 'react';
import Component from './Component';

function Text() {
    const [b] = useState([
        {
            id: 1,
            heading:'Siliguri Sutra Studios',
            imageUrl: './assets/Home/studio.png',
            desc: 'Siliguri Sutra Studios is team of professional photographers and videographers who are highly experienced and are equipped with one of the most modern & high-quality camera’s & gadgets.',
            insta_link: '',
        },
        {
            id: 2,
            heading:'Siliguri Sutra Marketing',
            imageUrl: './assets/Home/ssm.png',
            desc: 'Siliguri Sutra Marketing Solutions is known for its best-quality services that it provides at a very affordable cost. The moto of the Brand is to help local brand go global. ',
            insta_link: '',
        },
        {
            id: 3,
            heading:'Siliguri United',
            imageUrl: './assets/Home/united.png',
            desc: 'Siliguri United the merchandise brand of Siliguri Sutra brings in one of the most trendy designs that are loved by youth by keeping a touch of uniqueness and a flavour of the hills!',
            insta_link: '',
        },
    ])
    return (
        <div className="overflow-hidden">            
            <div className="lg:mt-10">                              
                <div className='md:flex md:justify-around mt-8 w-10/12 m-auto gap-6 z-20'>
                    {
                        b.map(({id, ...otherprops}) => (
                            <Component key={id} {...otherprops} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Text;
