import React from 'react'
import Circle from '../Componentes/About/Circle'
import ClientHeader from '../Componentes/Client/ClientHeader'
import Step from '../Componentes/Client/Step'
import {Helmet} from "react-helmet";
import Map from '../Componentes/Client/Map';

function Client() {
    return (
        <div className="relative z-30 lg:mb-10">
            <Helmet>
                <title>Siliguri Sutra -Contact</title>
                <link rel="shortcut icon" href="./favicon.ico" type="image/x-icon"></link>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                name="description"
                content="Connect with Siliguri sutra for the content and the best digital marketing services in Siliguri, with cheap and best products available"
                />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://siligurisutra.com"/>
                <meta property="og:title" content="Siliguri Sutra - Contact Us"/>
                <meta property="og:description" content="Connect with Siliguri sutra for the content and the best digital marketing services in Siliguri, with cheap and best products available"/>
                <meta property="og:image" content="./siliguri_sutra.svg"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://siligurisutra.com"/>
                <meta property="twitter:title" content="Siliguri Sutra - Contact Us"/>
                <meta property="twitter:description" content="Connect with Siliguri sutra for the content and the best digital marketing services in Siliguri, with cheap and best products available"/>
                <meta property="twitter:image" content="./siliguri_sutra.svg"/>
                <meta name="next-head-count" content="14"/>            
            </Helmet>
            <Circle/>
            <ClientHeader/>            
            <Step/>
            <Map />
        </div>
    )
}

export default Client
