import React, {useState} from 'react';
import PicCard from './PicCard';

function PicImg() {
    const [b] = useState([
        {
            id: 1,
            service:'Wedding & Pre-Weeding Photography & Videography',
            imageUrl: './assets/service/weeding.svg',
        },
        {
            id: 2,
            service:'Product Photography',
            imageUrl: './assets/service/product.svg',
        },
        {
            id: 3,
            service:'Birthdays & Corporate Events',
            imageUrl: './assets/service/corporate.svg',
        },
    ])
    return (
        <div className="overflow-hidden">            
            <div className="lg:mt-10">
            <p className="font-sans relative text-xl md:text-2xl font-bold text-center text-white md:mb-10">PHOTOGRAPHY</p>
                <div className='md:flex md:justify-around mt-8 w-10/12 m-auto grid grid-cols-3 gap-4 md:gap-12 z-20 mb-10'>
                    {
                        b.map(({id, ...otherprops}) => (
                            <PicCard key={id} {...otherprops} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PicImg;
