import React from 'react'
import Lottie from "react-lottie";
import thank from '../lotties/thanks.json';
import {Link} from 'react-router-dom';

function Popup(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: thank,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

    return (props.trigger)?(
        <div className="fixed top-12 left-0 w-full h-full flex justify-center items-center z-40" style={{backgroundColor: "rgba(0, 0, 0, 0.4)"}}>
            <div className="mx-auto relative px-12 py-6 w-8/12 md:w-4/12 bg-violate-default rounded-2xl">
                <Lottie 
                    options={defaultOptions}
                    className=""
                />
                <button onClick={()=> props.setTrigger(false)} className="absolute top-4 right-10 text-white font-extrabold">X</button>
                {props.children}
                <div className="flex justify-center mt-6">
                    <button className=" text-xs md:text-base bg-white px-6 py-1 rounded-2xl text-primary-default font-bold">
                        <Link to="/">Go to Home</Link>
                    </button>

                </div>
            </div>
        </div>
    ): "";
}

export default Popup
