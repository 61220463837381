import React, {useState} from 'react';
import Design from './Design';

function Show() {
    const [b] = useState([
        {
            id: 1,
            imageUrl: './assets/Instagram_pic/1.jpg',
            insta_link: 'https://www.instagram.com/p/CO5hwMFt523/?utm_medium=copy_link',
        },
        {
            id: 2,
            imageUrl: './assets/Instagram_pic/2.jpg',
            insta_link: 'https://www.instagram.com/p/CEMocLageF-/?utm_medium=copy_link',
        },
        {
            id: 3,
            imageUrl: './assets/Instagram_pic/3.jpg',
            insta_link: 'https://www.instagram.com/p/CLegEwqAYRp/?utm_medium=copy_link',
        },
        {
            id: 4,
            imageUrl: './assets/Instagram_pic/4.jpg',
            insta_link: 'https://www.instagram.com/p/CNegusJA-bt/?utm_medium=copy_link',
        },
        {
            id: 5,
            imageUrl: './assets/Instagram_pic/5.jpg',
            insta_link: 'https://www.instagram.com/p/CN9aQNrgnIW/?utm_medium=copy_link',
        },
        {
            id: 6,
            imageUrl: './assets/Instagram_pic/6.jpg',
            insta_link: 'https://www.instagram.com/p/CIdVz9oA95J/?utm_medium=copy_link',
        },
    ])
    return (
        <div className="overflow-hidden">            
            <div className="lg:mt-10">
                <div className="flex text-white font-sans text-sm md:text-xl font-bold items-center justify-center mb-10">
                    <div>
                        <svg className="w-6 h-6 md:w-10 md:h-10" xmlns="http://www.w3.org/2000/svg" width="55.386" height="55.385" viewBox="0 0 55.386 55.385">
                        <g id="Group_7" data-name="Group 7" transform="translate(-1402.13 -2758.641)">
                            <g id="Group_8" data-name="Group 8" transform="translate(1402.13 2758.641)">
                            <path id="Path_75" data-name="Path 75" d="M585.242,280.061A11.572,11.572,0,0,1,596.78,291.6v23.077a11.572,11.572,0,0,1-11.539,11.539H562.164a11.572,11.572,0,0,1-11.539-11.539V291.6a11.572,11.572,0,0,1,11.539-11.539h23.078m0-4.615H562.164A16.2,16.2,0,0,0,546.01,291.6v23.077a16.2,16.2,0,0,0,16.154,16.154h23.078A16.2,16.2,0,0,0,601.4,314.678V291.6a16.2,16.2,0,0,0-16.154-16.154Z" transform="translate(-546.01 -275.447)" fill="#f2f2f2"/>
                            <path id="Path_76" data-name="Path 76" d="M585.3,289.332a3.453,3.453,0,1,1,2.45-1.012A3.462,3.462,0,0,1,585.3,289.332Zm-15,2.308a9.231,9.231,0,1,1-9.231,9.231,9.231,9.231,0,0,1,9.231-9.231m0-4.615a13.846,13.846,0,1,0,13.847,13.846A13.846,13.846,0,0,0,570.3,287.024Z" transform="translate(-542.137 -273.178)" fill="#f2f2f2"/>
                            </g>
                        </g>
                        </svg>
                    </div>
                    <p className="ml-4">Follow us on Instagram <a href="https://www.instagram.com/siliguri_sutra/" target="blank">@siliguri_sutra</a></p>
                </div>                              
                <div className='w-8/12 m-auto grid grid-rows-3 md:grid-rows-2 grid-flow-col gap-6 z-20 mb-16'>
                    {
                        b.map(({id, ...otherprops}) => (
                            <Design key={id} {...otherprops} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Show;
