import React, {useEffect, useState} from 'react';
import ContentPop from './ContentPop';
import Cookies from 'js-cookie';

function PopTime() {
    const [timedPopup, Settimedpopup] = useState(false);
    const [readCookie] = useState(Cookies.get('name'));

    useEffect(()=> {
        setTimeout(() =>{
            Settimedpopup(true);
        }, 4000);
    }, [])

    return (
        <div>
            {readCookie === "sambit_saha" ?
                <div></div>
            :
                <div className="z-30 relative">
                    <ContentPop trigger={timedPopup} setTrigger={Settimedpopup}>
                    </ContentPop>
                </div>
            }
        </div>
        
    )
}

export default PopTime
