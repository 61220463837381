import React from 'react'

function AboutHeader() {
    return (
        <div className="text-white pt-20 px-6 pb-10 relative">            
            <div className="w-9/12 md:w-4/12 mx-auto">
                <div className="flex justify-start pt-2 pb-2">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 ml-8 md:ml-0 lg:ml-12 shadow-green rounded-lg z-20"></div>            
                </div>
                <p className="font-sans text-lg md:text-2xl lg:text-3xl font-bold text-center">About Siliguri Sutra</p>
                <div className="flex justify-end pt-2 pb-4">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 mr-8 md:mr-0 lg:mr-12 shadow-green rounded-lg z-20"></div>            
                </div>
            </div>
            <div className="z-20">
                <p className="italic text-sm md:text-base text-center md:px-14 pt-4">
                    Siliguri Sutra was founded by <b>Arijit Roy</b> on 14th Nov,2019,it started simply as a Community page on Instagram with
                    a sole motive to represent its city and give a platform to young & enthusiastic photographers. With overwhelming 
                    support of the people Siliguri Sutra started achieving new heights every day, and expanding the team became the need 
                    of the hour and that’s the point when 5 very talented members joined in – Shubhajit Adhikary as Co-admin and 
                    Content Creator, Kumarjeet Ray as  Administration & Photography Team Head ,Madhuparna Deb as Content writer & 
                    Content Research Head, Sambeet Jana as Fb Admin & Marketing Manager &
                    Sambit Saha as Web Developer & Creative Head.<br/><br/><br/>

                    With all this bunch of talented members within a year Siliguri Sutra grew to <b>10K+ Followers</b> on Instagram 
                    and became the <b>#1 Community of Siliguri</b>, the <b>#siligurisutra</b> became the third most popular hashtag in Siliguri.
                    In mid 2020 we launched our first brand Siliguri United which is a Merchandise brand and it received a good 
                    support from the audience. By end of 2020 Siliguri Sutra now had a 100+ members team spread across West Bengal. 
                    In January 2021 we launched Siliguri Sutra Studios to deliver the best photography & videography experience 
                    across the city.in this span of 1,.5 years we had an opportunity to work with some big brands in the market like  
                    <b> Motorola, Lays, Inox, Pepsi, Courtyard Siliguri, Casino Mayfair, Absolute Barbecues</b> etc.
                    Recently in the month of March we launched Siliguri Sutra Marketing 
                    Solutions which is a 360 Digital & Online Marketing Solutions Company, the company has a team of 
                    15 professionals & best from each field to deliver the
                    best experience to their client at a very affordable cost and the sole motive of the brand is to help Local brands go Global.        
                </p>
            </div>
        </div>
    )
}

export default AboutHeader
