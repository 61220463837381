import React from 'react'

function ClientHeader() {
    return (
        <div>
           <div className="w-6/12 md:w-3/12 text-white mt-20 md:pl-6">
                <div className="flex justify-start pt-2 pb-2">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 ml-8 md:ml-0 lg:ml-12 shadow-green rounded-lg z-20"></div>            
                </div>
                <p className="font-sans text-xl md:text-2xl lg:text-3xl font-bold text-center">Get In Touch</p>
                <div className="flex justify-end pt-2 pb-4">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 mr-8 md:mr-0 lg:mr-12 shadow-green rounded-lg z-20"></div>            
                </div>
            </div>
            <span className="inline-flex justify-center opacity-80 mt-8 md:mt-24 ml-8 lg:ml-14 mb-2">
                <a target="blank" href="https://www.facebook.com/siligurisutra/" className="text-primary-default bg-white rounded-full px-2 py-2">
                    <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                </a>
                <a target="blank" href="http://twitter.com/siligurisutra" className="ml-6 text-primary-default bg-white rounded-full px-2 py-2">
                    <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                </a>
                <a target="blank" href=" https://instagram.com/siliguri_sutra" className="ml-6 text-primary-default bg-white rounded-full px-2 py-2">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                </a>
                <a target="blank" href=" https://www.linkedin.com/company/siliguri-sutra" className="ml-6 text-primary-default bg-white rounded-full px-2 py-2">
                    <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                </a>
            </span> 
        </div>
    )
}

export default ClientHeader
