import React, {useState} from "react";
import { useSpring, animated } from "react-spring";
import web from './Images/siliguri_sutra.svg';
import {animateScroll as scroll} from 'react-scroll';
import {Link} from 'react-router-dom';



function Navbar() {
    const [b, setB] = useState(false);
    const fade = useSpring({
        opacity: b ? 1 : 0,  
        transform: b ? 'translate3d(0%,0,0)' : 'translate3d(100%,0,0)',
    });

    const toggleHome =() =>{
        scroll.scrollToTop()
    }
    return (
        <div className="font-sans flex flex-col items-center">
            <header className="bg-gradient-to-r from-primary-default to-secondary-default w-full px-9 py-3 flex justify-around fixed z-50">
                <div className="w-full md:flex justify-between items-center">
                    <div onClick={toggleHome} className="flex justify-between items-center text-secondary-default">                         
                        <img src={web} className="w-12 h-12 ml-4" onClick={toggleHome} alt="main_logo"/>
                        <p className="text-center text-xl md:text-left leading-tight md:ml-4 font-extrabold text-white md:flex">
                            Siliguri<br/>
                            Sutra
                        </p>

                        <button type="button" onClick={() => setB(!b)} className="text-white md:hidden hover:text-gray-400 focus:outline-none mr-4">
                            {b === false ? 
                                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/></svg>
                                :
                                <svg className="w-6 h-6 fill-current" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                            }
                        </button>                                              
                    </div>                     
                    <div className="hidden md:inline-flex md:flex-row">
                        <Link to="/"  onClick={toggleHome} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                            <span>Home</span>
                        </Link>
                        <Link to="/about" onClick={toggleHome} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                            <span>About</span>
                        </Link>
                        <Link to="/service" onClick={toggleHome} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                            <span>Service</span>
                        </Link>
                        <Link to="/clients" onClick={toggleHome} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                            <span>Clients</span>
                        </Link>
                        <Link to="/contact" onClick={toggleHome} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default ">
                            <span>Contact</span>
                        </Link>                       
                    </div>                    
                </div>                                   
            </header>

            {/* mobile animation */}
            <animated.div style={fade} className="fixed top-16 w-full h-full bg-gradient-to-r from-primary-default to-secondary-default flex flex-col justify-center items-center md:hidden z-50">
                <Link to="/" onClick={() => setB(!b)} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                    <span>Home</span>
                </Link>
                <Link to="/about" onClick={() => setB(!b)} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                    <span>About</span>
                </Link>
                <Link to="/service" onClick={() => setB(!b)} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                    <span>Service</span>
                </Link>
                <Link to="/clients" onClick={() => setB(!b)} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                    <span>Clients</span>
                </Link>
                <Link to="/contact" onClick={() => setB(!b)} className="ml-2 px-3 py-1 rounded text-white font-semibold hover:bg-neon-default cursor-pointer focus:bg-neon-default">
                    <span>Contact</span>
                </Link>
            </animated.div>
        </div>
    )
}

export default Navbar
