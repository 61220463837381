import React, {useState} from 'react';
import TeamCardStyle from './TeamCardStyle';

function OurTeam() {
    const [b] = useState([
        {
            id: 1,
            name:'Arijit Roy',
            imageUrl: './assets/Team/Arijit.jpg',
            desig: 'Founder of Siliguri Sutra & Strategist',
            insta_link: 'https://instagram.com/iamroy_official',
            fb_link: 'https://www.facebook.com/aritro.roy.1420',
        },
        {
            id: 2,
            name:'Kumarjeet Ray',
            imageUrl: './assets/Team/kumarjeet.jpg',
            desig: 'Administration & Photography Head',
            insta_link: 'https://instagram.com/the_joker_clicks',
            fb_link: 'https://www.facebook.com/kumarjeet.ray.52',
        },
        {
            id: 3,
            name:'Shubhajit Adhikary',
            imageUrl: './assets/Team/Shubhajit.jpg',
            desig: 'Co-Admin & Content Creator',
            insta_link: 'https://instagram.com/bhooter__raja',
            fb_link: 'https://www.facebook.com/shubhajit.adhikary.50',
        },
        {
            id: 4,
            name:'Madhuparna Deb',
            imageUrl: './assets/Team/Madhuparna.jpg',
            desig: 'Content Writer & Research Head',
            insta_link: 'https://instagram.com/madhuparna1408',
            fb_link: 'https://www.facebook.com/debmadhuparna796',
        },
        {
            id: 5,
            name:'Sambeet Jana',
            imageUrl: './assets/Team/sambeet.jpg',
            desig: 'FB Admin & Marketing Head',
            insta_link: 'https://instagram.com/__bhalo._.chele__',
            fb_link: 'https://www.facebook.com/profile.php?id=100011496079985',
        },
        {
            id: 6,
            name:'Sambit Saha',
            imageUrl: './assets/Team/sambit.jpg',
            desig: 'Web Developer & Creative Head',
            insta_link: 'https://instagram.com/creative_sambit',
            fb_link: 'https://www.facebook.com/sambit.saha.969/',
        },
    ])
    return (
        <div className="overflow-hidden">            
            <div className="lg:mt-10">
                <p className="font-sans relative text-xl md:text-2xl font-bold text-center text-white mb-10">Meet Our Team</p>                              
                <div className='w-10/12 mx-auto grid grid-rows-3 lg:grid-rows-2 grid-flow-col gap-6 z-20 mb-4'>
                    {
                        b.map(({id, ...otherprops}) => (
                            <TeamCardStyle key={id} {...otherprops} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default OurTeam;
