import React from 'react'

function TeamCardStyle(props) {
    return (
        <div className="flex justify-center ">
            <div className='w-full md:w-11/12 md:pb-10 bg-gradient-to-t from-primary-deep to-secondary-deep mb-4 origin-center transition duration-500 ease-in-out transform hover:scale-110 shadow-dark text-white rounded-xl'>
                <div className="flex justify-center pt-6">
                    <img src={props.imageUrl} className="overflow-hidden w-16 h-16 md:w-28 md:h-28 bg-violate-viol rounded-full" alt="insta_pic"/>
                </div>

                <div className='w-full md:py-4 text-center px-4'>
                    <p className="font-sans font-bold text-xs md:text-xl mb-1 md:mb-2">{props.name}</p>
                    <h3 className=' text-xxs md:text-sm pb-2 px-4'>{props.desig}</h3>                
                </div>
                <span className="flex justify-between px-6 md:px-14 pb-4 mt-4">
                    <a href={props.fb_link} target="blank" className="text-secondary-deep bg-white rounded-full px-1 py-1 md:px-2 md:py-2">
                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                        </svg>
                    </a>
                    <a href={props.insta_link} target="blank" className="text-secondary-deep bg-white rounded-full px-1 py-1 md:px-2 md:py-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                    </a>
                </span>
            </div>
        </div>
    )
}

export default TeamCardStyle
