import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles// ..
AOS.init();

function PicCard(props) {
    return(
        <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" className='w-full md:w-4/12 pb-4 md:pb-14 bg-gradient-to-t from-primary-deep to-secondary-deep shadow-dark text-white rounded-lg lg:rounded-3xl'>
            <div className="flex justify-center pt-3">
                <img src={props.imageUrl} className="overflow-hidden w-11/12" alt="service_pic"/>
            </div>

            <div className='w-full pt-4 text-center px-4'>
                <p className="font-sans font-bold text-xxs lg:text-lg md:text-xl ">{props.service}</p>           
            </div>
        </div>
    )
};

export default PicCard;
