import React from 'react';

function Component(props) {
    return(
        <div className='w-full pb-4 md:pb-14 mt-20 bg-gradient-to-t from-primary-deep to-secondary-deep mb-4 origin-center transition duration-500 ease-in-out transform hover:scale-110 shadow-dark text-white rounded-xl'>
            <div className="flex justify-center -mt-12 pt-2">
                <img src={props.imageUrl} className="overflow-hidden w-16 h-16 md:w-28 md:h-28" alt="insta_pic"/>
            </div>

            <div className='w-full md:py-4 text-center px-4'>
                <p className="font-sans font-bold text-lg md:text-xl md:mb-6">{props.heading}</p>
                <h3 className=' text-xxs md:text-sm pb-2 px-4'>{props.desc}</h3>                
            </div>
        </div>
    )
};

export default Component;
