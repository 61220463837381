import React, {useState, useEffect} from "react";
import Footer from "./Componentes/Footer";
import Navbar from "./Componentes/Navbar";
import PopTime from "./Componentes/Popup/PopTime";
import Client from "./Pages/Client";
import Clients from "./Pages/Clients";
import Service from "./Pages/Service";
import About from "./Pages/About";
import Home from "./Pages/Home";
import {Route} from "react-router-dom";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

function App() {
  // page loader
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },3000)
  }, [])

  return (
    <div className="bg-gradient-to-r from-primary-default to-secondary-default h-full">
      
        {loading ?
        <div className="flex justify-center items-center w-full h-screen">
          <ClimbingBoxLoader color={"#6C63FF"} loading={loading} size={20}/>
        </div>        
        :        
        <div>
          <PopTime/>
          <Navbar/>
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/clients" component={Clients}/>
          <Route path="/service" component={Service}/>
          <Route path="/contact" component={Client}/>
          <Footer/>
        </div>
        }
      
    </div>
  );
}

export default App;