import React, {useState} from 'react';
import ServiceDesign from './ServiceDesign';

function MainServiceText() {
    const [b] = useState([
        {
            id: 1,
            no: 1,
            service:'Social Media Marketing & Management',
            imageUrl: './assets/service/marketting.svg',
            des: 'Social media marketing is the act of increasing brand awareness amongst customers to generate traffic from social media platforms. The various sites like Facebook, Twitter, Instagram, Youtube, and Pinterest are the most used platforms that help in lead generation. Social media marketing has accorded maximum benefits to businesses. In today’s tech-savvy world, one cannot imagine their existence without social media. In short, if you want maximum profits & avail the best services of social media marketing in Siliguri.',
        },
        {
            id: 2,
            no: 2,
            service:'Search Engine Optimization',
            imageUrl: './assets/service/seo.svg',
            des: 'Search Engine Optimization is an indispensable technique of digital marketing that helps to optimize websites so that they can rank higher in search engines. This is an extremely viable method to increase the visibility of a website which will further increase leads and traffics. SEO service  will help you overtake your competitors easily by increasing your reach in the market and by making your website user-friendly, faster, and easier. Therefore, connect with Siliguri Sutra Marketing Solutions providing SEO service to elevate the quality of your website.',
        },
        {
            id: 3,
            no: 3,
            service:'Website Development',
            imageUrl: './assets/service/webdev.svg',
            des: 'Website development is the method of developing a website that embraces numerous works like web markup, coding, e-commerce development, static development, dynamic development, client-side scripting, server-side scripting. From a simple task like creating a plain-text webpage to creating a complex web application. A website is a mandatory online presence that acts as a bridge between you and your prospects. It elevates your online presence, reaches your target audience easily, escalates leads and traffic, converts your visitors into prospects, highly professional, and helps integrates business. All these advantages of web development services will surely help your business gain more profits.  ',
        },
        {
            id: 4,
            no: 4,
            service:'Logo & Graphic Designing',
            imageUrl: './assets/service/graphic.svg',
            des: 'We provide a catchy, unique and tailor-made logo designs that suit the needs of our clients. It is a must-have for every business of any company, even the ones with modest expectations. Siliguri Sutra Marketing Solutions has plenty of experience in providing professional logo design, flyers, posters, social media creatives and other graphic designing services that should help you be noticed. ',
        },    
        {
            id: 5,
            no: 5,
            service:'GOOGLE ADS (PPC)',
            imageUrl: './assets/service/Ads.svg',
            des: 'Google Ads is Google’s online advertising program, the program allows you to create online ads to reach audiences that are interested in the products and services you offer. The Google Ads platform runs on pay-per-click (PPC) advertising, i.e. you have to pay every time a visitor clicks your ad.',
        },
        {
            id: 6,
            no: 6,
            service:'Influencer Marketing',
            imageUrl: './assets/service/Influencer.svg',
            des: 'Influencer Marketing is a hybrid of old and new marketing tools. It takes the idea of celebrity endorsement and places it into a modern-day content-driven marketing campaign. The main differentiator in the case of influencer marketing is that the results of the campaign are collaborations between brands and influencers. But influencer marketing doesn’t just involve celebrities. Instead, it revolves around influencers, many of whom would never consider themselves famous in an offline setting',
        },
        {
            id: 7,
            no: 7,
            service:'Email Marketing',
            imageUrl: './assets/service/Email.svg',
            des: 'Email marketing is the highly effective digital marketing strategy of sending emails to prospects and customers. Effective marketing emails convert prospects into customers, and turn one-time buyers into loyal, raving fans.',
        },
        {
            id: 8,
            no: 8,
            service:'Brand Building & Consultation',
            imageUrl: './assets/service/brand.svg',
            des: 'Brand Building is generating awareness, establishing and promoting company using strategies and tactics. In other words brand building is enhancing brand equity using advertising campaigns and promotional strategies. Branding is crucial aspect of company because it is the visual voice of the company. Goal of brand building is creating a unique image about the company',
        },
        {
            id: 9,
            no: 9,
            service:'Bulk SMS ',
            imageUrl: './assets/service/sms.svg',
            des: 'Bulk SMS enables its users to send mass text messages nationally and internationally. This is the reason why this type of SMS delivery is one of the best solutions for businesses aiming to reach a specific audience, locally or globally. The most common use of bulk SMS messaging is for: Mass SMS advertising.',
        },
        {
            id: 10,
            no: 10,
            service:'Online Reputation Management(ORM)',
            imageUrl: './assets/service/orm.svg',
            des: 'Online reputation management (ORM) is the practice of crafting strategies that shape or influence the public perception of an organization, individual or other entity on the Internet. It helps drive public opinion about a business and its products and services.',
        },
    ])
    return (
        <div className="overflow-hidden">            
            <div className="lg:mt-10">
                <p className="font-sans relative text-xl md:text-2xl font-bold text-center text-white mb-10">360 DIGITAL & ONLINE MARKETING </p>                              
                <div className='w-11/12 mx-auto grid grid-rows-10 z-20 mb-4'>
                    {
                        b.map(({id, ...otherprops}) => (
                            <ServiceDesign key={id} {...otherprops} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MainServiceText;
