import React, {useState} from 'react';
import Popup from './Popup';

function Step() {
    const [buttonPopup, setbuttonPopup] = useState(false);
    const [data, setData] = useState({
        FullName: "",
        Email: "",
        Comapny: "",
        ContactNo: "",
        ProjectDetails: ""
    });

    const { FullName, Email, Comapny, ContactNo, ProjectDetails } = data;

    const handelChange = (e) =>{
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handelSubmit = async(e) =>{
        e.preventDefault();
        setbuttonPopup(true);

        try {
            const response = await fetch('https://v1.nocodeapi.com/siliguri_sutra/google_sheets/MrdASfqYZtxhHIdI?tabId=Sheet1', {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([
                    [FullName, Email, Comapny, ContactNo, ProjectDetails, new Date().toLocaleString()],
                ]),
            });            
            await response.json();
            setData({ ...data, FullName:"", Email:"", Comapny:"", ContactNo:"", ProjectDetails: ""})
        }catch(err) {
            console.log(err);
        }
    };

    return (
        <div className="z-20 relative md:px-3 lg:px-8">
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-12 md:py-24 mx-auto flex flex-wrap">
                    <div className="flex flex-wrap w-full">
                        <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                            <div className="pb-12 pr-8 lg:pr-0">
                                <p className="text-xl md:text-3xl font-bold text-white pr-8 pb-4">What will be next step ?</p>
                                <p className="text-white">You are one step closer to build your perfect product</p>
                            </div>
                            <div className="flex relative pb-12">
                                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                    </svg>
                                </div>
                                <div className="flex-grow pl-4">
                                    <h2 className="font-bold title-font text-base text-white mb-1 tracking-wider">1. We'll prepare a proposal</h2>
                                    <p className="leading-relaxed text-white">Required scope, timeline and apr. price will be included if you provide us with detail information about a project</p>
                                </div>
                            </div>
                            <div className="flex relative pb-12">
                                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                    </svg>
                                </div>
                                <div className="flex-grow pl-4">
                                    <h2 className="font-bold title-font text-base text-white mb-1 tracking-wider">2. Together we discuss it</h2>
                                    <p className="leading-relaxed text-white">Let's get acquainted and discuss all the possible variants and options. Google Hangouts or Skype usually works great</p>
                                </div>
                            </div>
                            <div className="flex relative pb-12">                                
                                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <circle cx="12" cy="5" r="3"></circle>
                                        <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                                    </svg>
                                </div>
                                <div className="flex-grow pl-4">
                                    <h2 className="font-bold title-font text-base text-white mb-1 tracking-wider">3. Let's start building</h2>
                                    <p className="leading-relaxed text-white">when the contract is signed, and all goals are set we can start the first sprint</p>
                                </div>
                            </div>                                                    
                        </div>
                        <div className=" overflow-hidden lg:w-3/5 md:w-1/2 rounded-3xl mt-12 bg-white" >
                            <div className="bg-secondary-light py-6 px-6 flex items-center">
                                <div className="bg-secondary-default rounded-lg p-4">
                                    <img src='./assets/contact/email.svg' alt="email_image"/>
                                </div>
                                <div>
                                    <p className="font-semibold text-primary-default ml-6 text-xs lg:text-base">Write us a few words about your project and we'll prepare a proposal for you within <b>24</b> hours.</p>
                                </div>
                            </div>
                            <form onSubmit={handelSubmit}>
                                <div className="lg:grid grid-cols-2 w-full lg:justify-around px-4 lg:px-2 py-4 gap-0">
                                    <input 
                                        type="text" 
                                        name="FullName" 
                                        autoComplete="off" 
                                        placeholder="Your Name *" 
                                        className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 mb-3 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                        value={FullName}
                                        onChange={handelChange}
                                        required
                                    />
                                    <input 
                                        type="email" 
                                        name="Email" 
                                        autoComplete="off" 
                                        placeholder="Your email *" 
                                        className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 mb-3 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                        value={Email}
                                        onChange={handelChange}
                                        required
                                    />
                                    <input 
                                        type="text" 
                                        name="Comapny" 
                                        autoComplete="off" 
                                        placeholder="Company *" 
                                        className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 mb-3 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                        value={Comapny}
                                        onChange={handelChange}
                                        required
                                    />
                                    <input 
                                        type="number" 
                                        name="ContactNo" 
                                        autoComplete="off" 
                                        placeholder="Contact no *" 
                                        className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                        value={ContactNo}
                                        onChange={handelChange}
                                        required
                                    />
                                </div>
                                <div className="lg:flex px-4 lg:px-6 w-full lg:justify-around ">
                                    <textarea 
                                        type="text" 
                                        name="ProjectDetails" 
                                        placeholder="Project details " 
                                        className="text-primary-default font-semibold bg-secondary-light w-full h-52 mb-3 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                        value={ProjectDetails}
                                        onChange={handelChange}
                                    />
                                </div>
                                <div className="w-full px-4  lg:px-6">
                                    <input 
                                        type="submit" 
                                        value="Send" 
                                        className="w-full px-4 py-1 lg:px-6 bg-primary-default text-white font-bold"
                                        disabled={(data.FullName === "" || data.Email === "" ||data.Comapny === "" ||data.ContactNo === "" ? true : false)}
                                    />
                                    <p className="text-center text-xs lg:text-base py-2 lg:pb-4 text-primary-default">
                                        If you need to have a DNA first, just contact us at
                                        <a href="mailto: siligurisutra@gmail.com"><b> siligurisutra@gmail.com</b></a> 
                                    </p>
                                </div>                                
                            </form>                            
                        </div>
                    </div>
                </div>
                <Popup trigger={buttonPopup} setTrigger={setbuttonPopup}>
                    <p className="text-white text-xs md:text-base text-center font-bold">
                        Thanks for connecting with us <br/>
                        We will contact you soon
                    </p>
                </Popup>
            </section>
        </div>
    )
}

export default Step
