import React from 'react'

function Map() {
    return (
        <div>
           <section className="text-gray-600 body-font relative mb-6">
                <div className=" px-6 md:px-14 pb-18 mx-auto flex">
                    <div className="bg-gray-300 rounded-lg overflow-hidden py-40 flex justify-center w-full relative px-10">
                        <iframe width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.6570146244317!2d88.4147723144069!3d26.723402183209426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e44180f070a04d%3A0xccc81d79a5fa627b!2sSiliguri%20Sutra!5e0!3m2!1sen!2sin!4v1621874518914!5m2!1sen!2sin" style={{filter: "grayscale(1) contrast(1.2) opacity(0.4)"}}></iframe>
                        <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                            <div className="lg:w-1/2 px-6">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                                <p className="mt-1">Hill Cart Road, Siliguri <br/>West Bengal - 734102</p>
                            </div>
                            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                                <a className="text-indigo-500 leading-relaxed" href="mailto: siligurisutra@gmail.com">siligurisutra@gmail.com</a>
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                                <p className="leading-relaxed"><a href="tel: +91 81451 19440">+91 81451 19440 </a></p>
                            </div>
                        </div>                       
                    </div>
                </div>
            </section> 
        </div>
    )
}

export default Map;
