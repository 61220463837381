import React from 'react'
import Border from '../Componentes/Common/Border'
import PicImg from '../Componentes/Services/Photography/PicImg'
import MainServiceText from '../Componentes/Services/ServiceComp/MainServiceText'
import ServiceHeader from '../Componentes/Services/ServiceHeader'
import {animateScroll as scroll} from 'react-scroll';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

function Service() {
    const toggleHome =() =>{
        scroll.scrollToTop()
    }

    return (
        <div className="text-white">
            <Helmet>
                <title>Siliguri Sutra -Services</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                name="description"
                content="Best marketing company in Siliguri means we provide lots of tings to develop a bussiness like graphic, web development, photography email and many more."
                />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://siligurisutra.com"/>
                <meta property="og:title" content="Siliguri Sutra - Services"/>
                <meta property="og:description" content="Best marketing company in Siliguri means we provide lots of tings to develop a bussiness like graphic, web development, photography email and many more."/>
                <meta property="og:image" content="./siliguri_sutra.svg"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://siligurisutra.com"/>
                <meta property="twitter:title" content="Siliguri Sutra - Services"/>
                <meta property="twitter:description" content="Best marketing company in Siliguri means we provide lots of tings to develop a bussiness like graphic, web development, photography email and many more."/>
                <meta property="twitter:image" content="./siliguri_sutra.svg"/>
                <meta name="next-head-count" content="14"/>
            </Helmet>
            <ServiceHeader/>
            <Border/>
            <MainServiceText/>
            <Border/>
            <PicImg/>
            <Border/>
            <p className="text-center font-sans text-sm md:text-xl font-bold pb-4 mt-6">For This kind of Exclusive Services</p>
            <div className="flex justify-center mb-16">                
                <button className="text-xs md:text-base bg-gradient-to-t from-violate-default via-secondary-vio to-violate-default px-6 py-1 rounded-2xl font-bold">
                    <Link to="/contact" onClick={toggleHome}>Contact Us</Link>
                </button>
            </div>
        </div>
    )
}

export default Service
