import React from 'react'

function ServiceHeader() {
    return (
        <div className="pt-20 md:px-6 text-white">
           <div className="w-5/12 md:w-3/12 mx-auto">
                <div className="flex justify-start pt-2 pb-2">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 ml-8 md:ml-0 lg:ml-12 shadow-green rounded-lg z-20"></div>            
                </div>
                <p className="font-sans text-lg md:text-2xl lg:text-3xl font-bold text-center relative z-10">Our Clients</p>
                <div className="flex justify-end pt-2 pb-4">
                    <div className="border-b-4 border-lightskyblue-default w-2/12 md:w-3/12 mr-8 md:mr-0 lg:mr-12 shadow-green rounded-lg z-20"></div>            
                </div>
            </div> 
            <div className="pb-8">
                <p className="text-center text-xxs md:text-xs lg:text-sm ">
                    Various types of Services<br/>
                    So what are you waiting for scroll down and avail them <b>NOW</b> !!
                </p>
            </div>
        </div>
    )
}

export default ServiceHeader;
