import React from 'react';
import Border from '../Componentes/Common/Border';
import Branches from '../Componentes/Home/Branches';
import Header from '../Componentes/Home/Header';
import Show from '../Componentes/Home/Instagram/Show';
import ServiceHome from '../Componentes/Home/ServiceHome';
import Text from '../Componentes/Home/SubBranches/Text';
import {Helmet} from "react-helmet";

function Home() {
    return (
        <div>
            <Helmet>
                <title>Siliguri Sutra - Photography & Digital Marketing</title>
                <link rel="shortcut icon" href="./favicon.ico" type="image/x-icon"></link>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                name="description"
                content="We are Siliguri Sutra & Siliguri Sutra is Best Digital Marketing Company in Siliguri and a leading company in West Bengal, Siliguri & Kolkata being its prime locations. Being a marketing company
                            it also has 3 ventures/Brands of its own in different fields. We have around 100+ members across West Bengal."
                />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://siligurisutra.com"/>
                <meta property="og:title" content="Siliguri Sutra - Photography & Digital Marketing"/>
                <meta property="og:description" content="We are Siliguri Sutra & Siliguri Sutra Digital Marketing always do research york for a company, we maintain good relationship with all of our clients. We believe in organic reach to grow a page"/>
                <meta property="og:image" content="./siliguri_sutra.svg"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://siligurisutra.com"/>
                <meta property="twitter:title" content="Siliguri Sutra - Photography & Digital Marketing"/>
                <meta property="twitter:description" content="We are Siliguri Sutra & Siliguri Sutra is Best Digital Marketing Company in Siliguri and a leading company in West Bengal, Siliguri & Kolkata being its prime locations. Being a marketing company
                            it also has 3 ventures/Brands of its own in different fields. We have around 100+ members across West Bengal."/>
                <meta property="twitter:image" content="./siliguri_sutra.svg"/>
                <meta name="next-head-count" content="14"/>            
            </Helmet>
           <Header/> 
           <Border/>
           <Branches/>
           <div className="h-96 py-72 -mt-16 md:-mt-24 w-full bg-primary-dark z-0 flex absolute" ></div>
           <Text/>
           <Border/>
           <ServiceHome/>
           <Border/>
           <Show/>
        </div>
    )
}

export default Home
