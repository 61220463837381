import React from 'react';

function Design(props) {
    return(
        <a href={props.insta_link} target="blank" className='overflow-hidden w-full bg-gradient-to-t from-primary-deep to-secondary-deep mb-4'>
            <div className="flex justify-center">
                <img src={props.imageUrl} className="" alt="insta_pic"/>
            </div>
        </a>
    )
};

export default Design;
