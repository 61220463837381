import React from 'react';
import web from '../Images/siliguri_sutra.svg';

function Branches() {
    return (
        <div className="text-white">
            <div className="text-center">
                <p className="font-sans text-xl md:text-2xl font-bold">Dedicated Branches of Our Work</p>
                <p className="font-sans text-xs">Our best branches provides you our best services</p>
            </div>

            <div className="flex justify-center items-center px-4 mt-9">
                <div className="z-20">
                    <img src={web} className="w-20 h-20 md:w-28 md:h-28" alt="main logo"/>
                </div>
                <div className="w-3/4 text-center ml-5 md:ml-10 bg-gradient-to-t from-primary-deep to-secondary-deep px-6 md:px-14 py-4 md:py-14 rounded-3xl z-20">
                    <p className="font-sans font-bold text-lg md:text-xl md:mb-6">Siliguri Sutra</p>
                    <p className="font-sans text-xxs md:text-sm">
                        Our main official branch from Siliguri fully dedicated in social media platform In contemporary times,
                        when the citizens of the city drain themselves physically, and emotionally, in the busy, metropolitan work culture, 
                        there is no time to look back and find appeasement in the jigsaw puzzle of the concrete buildings. 
                        Here is where we, at Siliguri Sutra come in.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Branches
