import React, {useState} from 'react';
import pop from '../Images/pop.PNG';
import Cookies from 'js-cookie';

function ContentPop(props) {
    const [data, setData] = useState({
        FullName: "",
        Email: "",
    });

    const { FullName, Email } = data;

    const handelChange = (e) =>{
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handelTrigger = (e) =>{
        props.setTrigger(false);
        Cookies.set('name', 'sambit_saha', { expires: 7, path: '' })
    }

    const handelSubmit = async(e) =>{
        e.preventDefault();
        props.setTrigger(false);
        Cookies.set('name', 'sambit_saha', { expires: 7, path: '' })

        try {
            const response = await fetch('https://v1.nocodeapi.com/siliguri_sutra/google_sheets/MrdASfqYZtxhHIdI?tabId=Sheet2', {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([
                    [FullName, Email, new Date().toLocaleString()],
                ]),
            });            
            await response.json();
            setData({ ...data, FullName:"", Email:"" })
        }catch(err) {
            console.log(err);
        }
    };

    return (props.trigger)?(
        <div className="fixed top-12 left-0 w-full h-full flex justify-center items-center z-40" style={{backgroundColor: "rgba(0, 0, 0, 0.4)"}}>
            <div className="mx-auto relative px-12 py-6 w-8/12 md:w-8/12 bg-white rounded-2xl overflow-hidden">                
                <button onClick={handelTrigger} className="absolute top-4 right-10 text-secondary-vio font-extrabold">X</button>
                <div className="flex">
                    <div className="hidden md:flex overflow-hidden w-8/12 lg:w-6/12 bg-gray-400">
                        <img src={pop} alt="pop_image" className=" lg:w-full" />
                    </div>
                    <div>
                        <p className="text-sm md:text-md lg:text-2xl font-bold text-center text-secondary-default lg:mt-12">Subscribe of Newsletter <br/> For latest news and update</p>
                        <form onSubmit={handelSubmit}>
                            <div className="w-full lg:justify-around px-4 lg:px-2 py-4 gap-0">
                                <input 
                                    type="text" 
                                    name="FullName" 
                                    autoComplete="off" 
                                    placeholder="Your Name *" 
                                    className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 mb-3 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                    value={FullName}
                                    onChange={handelChange}
                                    required
                                />
                                <input 
                                    type="email" 
                                    name="Email" 
                                    autoComplete="off" 
                                    placeholder="Your email *" 
                                    className="text-primary-default font-semibold bg-secondary-light w-full lg:w-11/12 lg:m-4 py-2 rounded-xl pl-2 placeholder-gray-500 outline-none"
                                    value={Email}
                                    onChange={handelChange}
                                    required
                                />                            
                            </div>                        
                            <div className="w-full px-4 lg:ml-2">
                                <input 
                                    type="submit" 
                                    value="Send" 
                                    className="text-xs w-full md:text-base bg-violate-viol px-6 py-1 rounded-2xl text-white font-bold"
                                    disabled={(data.FullName === "" || data.Email === "" ? true : false)}
                                />                            
                            </div>                                
                        </form>
                    </div>
                </div>
            </div>
        </div>
    ): "";
}

export default ContentPop;
