import React from 'react'

function Circle() {
    return (
        <div className="z-0 absolute top-16 right-0 w-7/12">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" width="790.999" height="1135.999" viewBox="0 0 990.999 1335.999">
                <path id="Subtraction_1" data-name="Subtraction 1" d="M9316,1336a678.718,678.718,0,0,1-68.3-3.449,669.668,669.668,0,0,1-66.325-10.123c-21.519-4.4-43.058-9.941-64.018-16.46-20.723-6.445-41.371-14-61.372-22.463-19.814-8.38-39.46-17.844-58.393-28.129-18.8-10.21-37.327-21.467-55.076-33.46-17.665-11.933-34.967-24.871-51.426-38.455-16.417-13.548-32.378-28.054-47.437-43.114s-29.564-31.019-43.114-47.437c-13.582-16.457-26.52-33.759-38.454-51.425-11.988-17.744-23.246-36.275-33.46-55.076-10.284-18.931-19.748-38.577-28.129-58.393-8.46-20-16.018-40.649-22.463-61.373-6.519-20.958-12.057-42.5-16.461-64.017A669.512,669.512,0,0,1,8651.45,736.3a677.932,677.932,0,0,1,0-136.6,669.517,669.517,0,0,1,10.122-66.326c4.4-21.522,9.942-43.06,16.461-64.017,6.445-20.723,14-41.372,22.463-61.373,8.382-19.816,17.846-39.463,28.129-58.393,10.214-18.8,21.472-37.332,33.46-55.077,11.936-17.666,24.873-34.968,38.454-51.425,13.55-16.419,28.056-32.379,43.114-47.437s31.02-29.565,47.438-43.114c16.459-13.583,33.761-26.521,51.426-38.455,17.749-11.992,36.279-23.249,55.076-33.46,18.933-10.285,38.578-19.749,58.393-28.129,20-8.46,40.649-16.017,61.372-22.463,20.959-6.519,42.5-12.057,64.018-16.461A669.671,669.671,0,0,1,9247.7,3.449,678.7,678.7,0,0,1,9316,0c14.508,0,29.173.471,43.59,1.4,14.278.92,28.692,2.313,42.843,4.141,14.021,1.811,28.156,4.1,42.012,6.8,13.739,2.676,27.564,5.827,41.092,9.366,13.424,3.511,26.912,7.5,40.088,11.85,13.085,4.321,26.206,9.114,39,14.247A667.445,667.445,0,0,1,9639,83.143V1252.857a667.53,667.53,0,0,1-74.379,35.342c-12.791,5.132-25.912,9.926-39,14.247-13.173,4.35-26.66,8.337-40.088,11.85-13.525,3.538-27.351,6.69-41.092,9.366-13.856,2.7-27.991,4.985-42.012,6.8-14.15,1.828-28.564,3.221-42.843,4.141C9345.172,1335.528,9330.507,1336,9316,1336Z" transform="translate(-8648.001 0)" fill="#1b1f37"/>
            </svg>
        </div>   
    )
}

export default Circle
