import React from 'react';
import web from './Images/siliguri_sutra.svg';
import {animateScroll as scroll} from 'react-scroll';
import {Link} from 'react-router-dom';

function Footer() {
    const toggleHome =() =>{
        scroll.scrollToTop()
    }

    return (
        <div>
            <footer className="relative body-font bg-gradient-to-r from-violate-viol to-violate-light rounded-tr-min md:rounded-tr-max h-80 lg:h-72 z-20">
                <div className="container px-5 pt-8 mx-auto flex items-center flex-col">
                    <div className="title-font font-medium items-center justify-center">
                        <img src={web} alt="profile_pic" className="w-16 h-16 md:w-20 md:h-20"/>                        
                    </div>
                    <span className="text-xl text-white font-bold mb-2 mt-2">Siliguri Sutra</span>
                    <span className="inline-flex justify-center text-white mb-4 text-sm md:text-base">
                        <p><Link to="/" onClick={toggleHome}>Home</Link></p>
                        <p className="ml-6"><Link to="/about" onClick={toggleHome}>About</Link></p>
                        <p className="ml-6"><Link to="/service" onClick={toggleHome}>Service</Link></p>
                        <p className="ml-6"><Link to="/clients" onClick={toggleHome}>Clients</Link></p>
                        <p className="ml-6"><Link to="/contact" onClick={toggleHome}>Contact</Link></p>
                    </span>  
                    <span className="inline-flex justify-center">
                        <a href="https://www.facebook.com/siligurisutra/" target="blank" className="text-violate-viol bg-white rounded-full px-2 py-2">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                        <a href="http://twitter.com/siligurisutra" target="blank" className="ml-6 text-violate-viol bg-white rounded-full px-2 py-2">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                        </a>
                        <a href="https://instagram.com/siliguri_sutra" target="blank" className="ml-6 text-violate-viol bg-white rounded-full px-2 py-2">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a href=" https://www.linkedin.com/company/siliguri-sutra" target="blank" className="ml-6 text-violate-viol bg-white rounded-full px-2 py-2">
                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 24 24">
                                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </span>
                    <div className="lg:flex lg:justify-between pt-4 text-white w-full">
                    <div className=" lg:hidden text-center text-xs lg:text-base mb-4">Contact us- <a href="tel: +91 81451 19440">+91 81451 19440 </a>/ Email us - <a href="mailto: siligurisutra@gmail.com">siligurisutra@gmail.com</a> </div>
                        <div className="text-center text-xs lg:text-base font-bold">Copyright © 2021 All rights reserved by Siliguri Sutra</div>
                        <div className="hidden lg:flex text-center text-xs lg:text-base">Contact us- <a href="tel: +91 81451 19440">+91 81451 19440 </a>/ Email us - <a href="mailto: siligurisutra@gmail.com">siligurisutra@gmail.com</a> </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
