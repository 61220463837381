import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles// ..
import { Link } from 'react-router-dom';
AOS.init();

function ServiceHome() {
    const toggleHome =() =>{
        scroll.scrollToTop()
    }
    
    return (
        <div className="text-white mt-6">
            <div className="text-center">
                <p className="font-sans text-xl md:text-2xl font-bold">Services</p>
                <p className="font-sans text-xs">Our best branches provides you our best services</p>
            </div> 
            <div className="md:flex justify-center md:justify-around items-center px-14 mt-9 m-auto w-9/12 pb-20">
                <div className="flex justify-center mb-6 md:mb-0" data-aos="flip-left" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                    <svg className="w-40 h-40 md:w-56 md:h-56 lg:w-80 lg:h-80" xmlns="http://www.w3.org/2000/svg" width="562.993" height="381.51" viewBox="0 0 562.993 381.51">
                    <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
                        <path id="Path_8" data-name="Path 8" d="M981.768,710.892A130.189,130.189,0,0,1,992.543,669.8q2.432-5.432,5.353-10.626a.793.793,0,0,0-1.369-.8A131.992,131.992,0,0,0,981.84,698.9q-1.1,5.956-1.657,12C980.088,711.908,981.674,711.9,981.768,710.892Z" transform="translate(-449.442 -330.885)" fill="#f2f2f2"/>
                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="10.043" cy="10.043" r="10.043" transform="translate(542.252 310.749)" fill="#f2f2f2"/>
                        <path id="Path_9" data-name="Path 9" d="M983.628,721.058a84.463,84.463,0,0,1,6.99-26.657q1.578-3.524,3.473-6.893a.515.515,0,0,0-.888-.519,85.633,85.633,0,0,0-9.528,26.286q-.715,3.864-1.075,7.783C982.538,721.717,983.568,721.713,983.628,721.058Z" transform="translate(-450.275 -340.784)" fill="#f2f2f2"/>
                        <circle id="Ellipse_12" data-name="Ellipse 12" cx="6.515" cy="6.515" r="6.515" transform="translate(539.793 335.343)" fill="#f2f2f2"/>
                        <path id="Path_10" data-name="Path 10" d="M975.146,720.64a84.461,84.461,0,0,1-10.884-25.317q-.924-3.749-1.5-7.572a.515.515,0,0,0-1.02.137,85.617,85.617,0,0,0,8.655,26.587q1.812,3.487,3.939,6.8C974.693,721.83,975.5,721.194,975.146,720.64Z" transform="translate(-443.091 -340.981)" fill="#f2f2f2"/>
                        <circle id="Ellipse_13" data-name="Ellipse 13" cx="6.515" cy="6.515" r="6.515" transform="translate(512.127 334.886)" fill="#f2f2f2"/>
                        <path id="Path_11" data-name="Path 11" d="M332.526,710.892A130.187,130.187,0,0,0,321.751,669.8q-2.432-5.433-5.353-10.626a.793.793,0,0,1,1.369-.8A131.989,131.989,0,0,1,332.454,698.9q1.1,5.956,1.658,12c.094,1.016-1.492,1.009-1.586,0Z" transform="translate(-220.796 -330.885)" fill="#f2f2f2"/>
                        <circle id="Ellipse_14" data-name="Ellipse 14" cx="10.043" cy="10.043" r="10.043" transform="translate(81.718 310.749)" fill="#f2f2f2"/>
                        <path id="Path_12" data-name="Path 12" d="M333.953,721.058a84.458,84.458,0,0,0-6.99-26.657q-1.578-3.524-3.473-6.893a.515.515,0,0,1,.888-.519,85.628,85.628,0,0,1,9.528,26.286q.715,3.864,1.075,7.783c.061.659-.968.655-1.029,0Z" transform="translate(-223.25 -340.784)" fill="#f2f2f2"/>
                        <circle id="Ellipse_15" data-name="Ellipse 15" cx="6.515" cy="6.515" r="6.515" transform="translate(91.233 335.343)" fill="#f2f2f2"/>
                        <path id="Path_13" data-name="Path 13" d="M341.417,720.641A84.459,84.459,0,0,0,352.3,695.323q.924-3.749,1.5-7.571a.515.515,0,0,1,1.02.137,85.625,85.625,0,0,1-8.655,26.587q-1.812,3.487-3.939,6.8c-.357.557-1.166-.079-.811-.633Z" transform="translate(-229.415 -340.981)" fill="#f2f2f2"/>
                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="6.515" cy="6.515" r="6.515" transform="translate(118.899 334.886)" fill="#f2f2f2"/>
                        <path id="Path_14" data-name="Path 14" d="M895,534.074l.157,118.081a8.278,8.278,0,0,1-8.256,8.28l-49.049.072c13.808-25.83,21.626-66.486,21.58-97.826a184.407,184.407,0,0,0-3.751-36.819l31.039-.045A8.277,8.277,0,0,1,895,534.074Z" transform="translate(-400.424 -285.357)" fill="#6c63ff"/>
                        <path id="Path_15" data-name="Path 15" d="M773.5,740.147H289.486a.781.781,0,0,1,0-1.561H773.5a.781.781,0,0,1,0,1.561Z" transform="translate(-211.291 -358.637)" fill="#3f3d56"/>
                        <path id="Path_16" data-name="Path 16" d="M769.465,494.71l-87.415-.343,8.5-28.28a10.883,10.883,0,0,1,10.361-7.7h.041l49.894.2a10.881,10.881,0,0,1,10.341,7.785Z" transform="translate(-346.763 -262.132)" fill="#ccc"/>
                        <path id="Path_17" data-name="Path 17" d="M885,499.033a9.178,9.178,0,0,0-9.178,9.178h18.356A9.178,9.178,0,0,0,885,499.033Z" transform="translate(-413.502 -276.132)" fill="#6c63ff"/>
                        <path id="Path_18" data-name="Path 18" d="M800.228,661.507H558.971a11.158,11.158,0,0,1-11.145-11.145V523.178a11.158,11.158,0,0,1,11.145-11.145H800.228a11.158,11.158,0,0,1,11.145,11.145V650.363A11.158,11.158,0,0,1,800.228,661.507ZM558.971,513.344a9.845,9.845,0,0,0-9.834,9.834V650.363a9.845,9.845,0,0,0,9.834,9.834H800.228a9.845,9.845,0,0,0,9.834-9.834V523.178a9.845,9.845,0,0,0-9.834-9.834Z" transform="translate(-300.535 -280.61)" fill="#fff"/>
                        <path id="Path_19" data-name="Path 19" d="M750.877,491.678H729.9a5.573,5.573,0,0,1,0-11.145h20.979a5.573,5.573,0,0,1,0,11.145Z" transform="translate(-361.324 -269.761)" fill="#fff"/>
                        <path id="Path_20" data-name="Path 20" d="M722.651,649.683a49.825,49.825,0,1,1,49.825-49.825A49.825,49.825,0,0,1,722.651,649.683Zm0-98.338a48.514,48.514,0,1,0,48.513,48.514A48.514,48.514,0,0,0,722.651,551.344Z" transform="translate(-343.587 -293.697)" fill="#fff"/>
                        <path id="Path_21" data-name="Path 21" d="M731.605,641.592a32.779,32.779,0,1,1,32.779-32.779A32.78,32.78,0,0,1,731.605,641.592Zm0-64.248a31.468,31.468,0,1,0,31.468,31.468,31.468,31.468,0,0,0-31.468-31.468Z" transform="translate(-352.541 -302.652)" fill="#3f3d56"/>
                        <circle id="Ellipse_17" data-name="Ellipse 17" cx="24.912" cy="24.912" r="24.912" transform="translate(354.152 281.248)" fill="#ccc"/>
                        <path id="Path_22" data-name="Path 22" d="M723.407,617.423A16.39,16.39,0,0,1,737.5,601.2a16.39,16.39,0,1,0,0,32.453A16.39,16.39,0,0,1,723.407,617.423Z" transform="translate(-359.421 -311.262)" fill="#fff"/>
                        <path id="Path_23" data-name="Path 23" d="M897.605,505.078H864.826v-.656a16.39,16.39,0,1,1,32.779,0Zm-31.454-1.311H896.28a15.079,15.079,0,0,0-30.129,0Z" transform="translate(-409.713 -272.344)" fill="#3f3d56"/>
                        <path id="Path_24" data-name="Path 24" d="M569.382,535.145a6.556,6.556,0,1,1,6.556-6.556A6.556,6.556,0,0,1,569.382,535.145Zm0-11.8a5.245,5.245,0,1,0,5.245,5.245A5.245,5.245,0,0,0,569.382,523.344Z" transform="translate(-305.701 -284.054)" fill="#3f3d56"/>
                        <circle id="Ellipse_18" data-name="Ellipse 18" cx="3.278" cy="3.278" r="3.278" transform="translate(260.403 241.257)" fill="#fff"/>
                        <circle id="Ellipse_19" data-name="Ellipse 19" cx="3.278" cy="3.278" r="3.278" transform="translate(277.448 241.257)" fill="#ccc"/>
                        <circle id="Ellipse_20" data-name="Ellipse 20" cx="3.278" cy="3.278" r="3.278" transform="translate(294.493 241.257)" fill="#ccc"/>
                        <path id="Path_25" data-name="Path 25" d="M595.382,535.145a6.556,6.556,0,1,1,6.556-6.556A6.556,6.556,0,0,1,595.382,535.145Zm0-11.8a5.245,5.245,0,1,0,5.245,5.245A5.245,5.245,0,0,0,595.382,523.344Z" transform="translate(-314.656 -284.054)" fill="#fff"/>
                        <path id="Path_26" data-name="Path 26" d="M621.382,535.145a6.556,6.556,0,1,1,6.556-6.556A6.556,6.556,0,0,1,621.382,535.145Zm0-11.8a5.245,5.245,0,1,0,5.245,5.245A5.245,5.245,0,0,0,621.382,523.344Z" transform="translate(-323.611 -284.054)" fill="#fff"/>
                        <rect id="Rectangle_21" data-name="Rectangle 21" width="85.227" height="1.311" transform="translate(247.946 288.377)" fill="#fff"/>
                        <rect id="Rectangle_22" data-name="Rectangle 22" width="83.11" height="1.311" transform="translate(247.946 314.766)" fill="#fff"/>
                        <rect id="Rectangle_23" data-name="Rectangle 23" width="85.227" height="1.311" transform="translate(424.956 288.377)" fill="#3f3d56"/>
                        <rect id="Rectangle_24" data-name="Rectangle 24" width="83.11" height="1.311" transform="translate(426.922 314.766)" fill="#3f3d56"/>
                        <path id="Path_27" data-name="Path 27" d="M438.919,382.763c-5.317-2.442-8.811-9.425-8.612-16.312s3.823-13.327,8.821-16.611a17.471,17.471,0,0,1,16.534-1.249c5.364,2.283,12.567,2.905,15.791,8.581,2.478,4.362,1.585,14.092-.169,18.941-1.516,4.192-5.224,6.554-8.84,7.875a29.725,29.725,0,0,1-24.592-1.981" transform="translate(-260.058 -223.798)" fill="#2f2e41"/>
                        <path id="Path_28" data-name="Path 28" d="M219.366,551.292l-7.644-2.487,5.953-30.664,11.281,3.67Z" transform="translate(-72.919 -178.453)" fill="#ffb8b8"/>
                        <path id="Path_29" data-name="Path 29" d="M9.76,0h15.5V9.76H0A9.76,9.76,0,0,1,9.76,0Z" transform="translate(124.77 363.372) rotate(18.021)" fill="#2f2e41"/>
                        <path id="Path_30" data-name="Path 30" d="M321.519,552.674h-8.038l-3.823-31H321.52Z" transform="translate(-106.65 -179.669)" fill="#ffb8b8"/>
                        <path id="Path_31" data-name="Path 31" d="M301.606,565.457h15.5v9.76h-25.26A9.76,9.76,0,0,1,301.606,565.457Z" transform="translate(-100.515 -194.749)" fill="#2f2e41"/>
                        <path id="Path_32" data-name="Path 32" d="M457.555,630.537c-.048,0-.1,0-.145,0l-8.907-.424a2.954,2.954,0,0,1-2.809-2.906L444.487,539.1a.983.983,0,0,0-1.853-.445L395.458,628.1a2.934,2.934,0,0,1-3.161,1.522l-8.864-1.688a2.95,2.95,0,0,1-2.23-3.88l34.372-97.389,2.645-28.809a2.93,2.93,0,0,1,2.58-2.655c10.016-1.23,24.01-9.437,29.562-12.908a2.915,2.915,0,0,1,2.315-.348,2.987,2.987,0,0,1,1.856,1.472,132.918,132.918,0,0,1,14.6,81.278l-8.665,63.291a2.934,2.934,0,0,1-2.918,2.551Z" transform="translate(-243.09 -270.213)" fill="#9b5d5d"/>
                        <circle id="Ellipse_21" data-name="Ellipse 21" cx="16.102" cy="16.102" r="16.102" transform="translate(174.389 133.528)" fill="#ffb8b8"/>
                        <path id="Path_33" data-name="Path 33" d="M433.9,485.578l-4.848-34.644a20.353,20.353,0,1,1,39.711-8.939c1.381,6.4,4.186,14.2,3.161,22.6h0C464.747,484.529,451.712,490.674,433.9,485.578Z" transform="translate(-259.457 -251.016)" fill="#b3b3b3"/>
                        <path id="Path_34" data-name="Path 34" d="M461.465,429.655h-11.8a2.953,2.953,0,0,1-2.95-2.95v-3.934a2.953,2.953,0,0,1,2.95-2.95h11.8a2.953,2.953,0,0,1,2.95,2.95V426.7A2.953,2.953,0,0,1,461.465,429.655Z" transform="translate(-265.711 -248.851)" fill="#b3b3b3"/>
                        <path id="Path_35" data-name="Path 35" d="M532.417,502.262A7.043,7.043,0,0,0,526.7,493.1L489.971,440.51l-10.892,10.768,39.531,48.306a7.081,7.081,0,0,0,13.806,2.678Z" transform="translate(-276.858 -255.976)" fill="#ffb8b8"/>
                        <path id="Path_36" data-name="Path 36" d="M499.492,454.324l-13.265,8.855a3.158,3.158,0,0,1-4.608-1.277l-6.795-14.376a8.77,8.77,0,1,1,14.617-9.7l10.639,11.748a3.158,3.158,0,0,1-.588,4.745Z" transform="translate(-274.889 -253.702)" fill="#b3b3b3"/>
                        <path id="Path_37" data-name="Path 37" d="M355.367,424.133a6.9,6.9,0,0,1,.157,1.075l28.162,16.247,6.845-3.941,7.3,9.553-11.44,8.154a5.245,5.245,0,0,1-6.293-.153l-29.04-22.91a6.882,6.882,0,1,1,4.311-8.025Z" transform="translate(-229.572 -248.507)" fill="#ffb8b8"/>
                        <path id="Path_38" data-name="Path 38" d="M414.3,458.487l-7.076-14.293a3.158,3.158,0,0,1,1.859-4.406l15.13-4.891a8.77,8.77,0,1,1,7.736,15.742l-13.018,9.041a3.158,3.158,0,0,1-4.631-1.193Z" transform="translate(-251.996 -253.733)" fill="#b3b3b3"/>
                        <path id="Path_39" data-name="Path 39" d="M473.152,351.748a2.664,2.664,0,0,0,2.83-1.58,3.836,3.836,0,0,0-.272-3.368,7.039,7.039,0,0,0-2.475-2.428,8.132,8.132,0,0,0-3.921-1.349,4.892,4.892,0,0,0-3.8,1.442,3.525,3.525,0,0,0-.637,3.886,5.2,5.2,0,0,0,3.028,2.2,17.762,17.762,0,0,0,7.459.98" transform="translate(-271.856 -222.397)" fill="#2f2e41"/>
                        <path id="Path_40" data-name="Path 40" d="M469.264,384.605c-2.814.132-2.33-5.839-3.21-8.516s-2.095-5.868-4.865-6.382c-2.27-.42-4.322,1.24-6.3,2.431-3.525,2.124-8.087,2.908-11.788,1.108s-5.952-6.607-4.152-10.308a8.442,8.442,0,0,1,5.277-4.157,16.984,16.984,0,0,1,6.859-.329,35.091,35.091,0,0,1,14.348,5.086c3,1.884,5.862,4.449,6.756,7.88s1.259,12.761-2.238,13.352Z" transform="translate(-262.819 -227.645)" fill="#2f2e41"/>
                        <path id="Path_41" data-name="Path 41" d="M473.588,337.262c-2.126-2.7-1.325-6.941,1.153-9.317s6.162-3.116,9.582-2.814a19.419,19.419,0,0,1,15.75,11.325c2.161,4.943,2.143,10.6,4.1,15.624a21.069,21.069,0,0,0,28.459,11.5c3.4-1.568,6.64-4.639,6.446-8.38a20.6,20.6,0,0,1-9.062,22.19c-4.3,2.586-9.39,3.464-14.37,4.068-3.246.394-6.581.687-9.749-.124a17.229,17.229,0,0,1-10.388-8.39,27.271,27.271,0,0,1-3.164-13.2c-.027-3.563.44-7.129.193-10.683s-1.315-7.232-3.892-9.693-6.926-3.271-9.754-1.1c-1.137.871-1.969,2.144-3.249,2.786s-3.3.143-3.387-1.287Z" transform="translate(-274.507 -216.217)" fill="#2f2e41"/>
                        <path id="Path_42" data-name="Path 42" d="M308.009,382.992H175.2a4.585,4.585,0,0,1-4.58-4.58v-74.8a4.585,4.585,0,0,1,4.58-4.58H308.009a4.585,4.585,0,0,1,4.579,4.58v74.8A4.585,4.585,0,0,1,308.009,382.992ZM175.2,300.05a3.566,3.566,0,0,0-3.562,3.562v74.8a3.566,3.566,0,0,0,3.562,3.562H308.009a3.566,3.566,0,0,0,3.562-3.562v-74.8a3.566,3.566,0,0,0-3.562-3.562Z" transform="translate(-170.622 -207.25)" fill="#fff"/>
                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="18.991" cy="18.991" r="18.991" transform="translate(29.371 106.309)" fill="#e6e6e6"/>
                        <path id="Path_43" data-name="Path 43" d="M308.045,374.415,285.753,335.8a1.587,1.587,0,0,0-2.75,0l-13.6,23.563-20.281-35.128a2.035,2.035,0,0,0-3.525,0l-28.581,49.5a2.035,2.035,0,0,0,1.763,3.053h87.9a1.588,1.588,0,0,0,1.374-2.381Z" transform="translate(-186.505 -215.581)" fill="#6c63ff"/>
                        <rect id="Rectangle_25" data-name="Rectangle 25" width="8.141" height="8.141" transform="translate(9.668 100.942)" fill="#fff"/>
                        <path id="Path_44" data-name="Path 44" d="M402.644,224.635H298.873a3.583,3.583,0,0,1-3.578-3.578V162.611a3.582,3.582,0,0,1,3.578-3.578H402.644a3.582,3.582,0,0,1,3.578,3.578v58.446A3.582,3.582,0,0,1,402.644,224.635ZM298.873,159.828a2.786,2.786,0,0,0-2.783,2.783v58.446a2.786,2.786,0,0,0,2.783,2.783H402.644a2.786,2.786,0,0,0,2.783-2.783V162.611a2.786,2.786,0,0,0-2.783-2.783Z" transform="translate(-213.561 -159.033)" fill="#ccc"/>
                        <circle id="Ellipse_23" data-name="Ellipse 23" cx="14.839" cy="14.839" r="14.839" transform="translate(104.683 11.35)" fill="#f2f2f2"/>
                        <path id="Path_45" data-name="Path 45" d="M402.672,217.933l-17.418-30.169a1.24,1.24,0,0,0-2.148,0l-10.63,18.411-15.847-27.447a1.59,1.59,0,0,0-2.755,0l-22.332,38.68a1.59,1.59,0,0,0,1.377,2.386H401.6a1.24,1.24,0,0,0,1.075-1.861Z" transform="translate(-225.971 -165.542)" fill="#e6e6e6"/>
                        <rect id="Rectangle_26" data-name="Rectangle 26" width="6.361" height="6.361" transform="translate(89.288 7.157)" fill="#ccc"/>
                    </g>
                    </svg>
                    <button className=" text-xs md:text-base absolute -bottom-6 bg-gradient-to-t from-violate-default via-secondary-vio to-violate-default px-6 py-1 rounded-2xl">
                        <Link to="/service" onClick={toggleHome}>Photography</Link>
                    </button>
                </div>
                <div className="hidden md:flex">
                    <svg className="h-44" xmlns="http://www.w3.org/2000/svg" width="246.501" height="551.501" viewBox="0 0 246.501 551.501">
                    <defs>
                        <filter id="Line_4" x="0" y="0" width="246.501" height="551.501" filterUnits="userSpaceOnUse">
                        <feOffset dx="5" dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="6" result="blur"/>
                        <feFlood flood-color="#f3cccc" flood-opacity="0.859"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Line_4)">
                        <line id="Line_4-2" data-name="Line 4" x1="204" y2="509" transform="translate(16.25 18.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="5"/>
                    </g>
                    </svg>
                </div>
                <div className="flex justify-center" data-aos="flip-right" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                    <svg className="w-40 h-40 md:w-56 md:h-56 lg:w-80 lg:h-80" xmlns="http://www.w3.org/2000/svg" width="562.99" height="381.51" viewBox="0 0 562.99 416.637">
                    <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
                        <path id="Path_46" data-name="Path 46" d="M245.646,751.869c0,13.12-7.8,17.7-17.42,17.7q-.334,0-.668-.007c-.446-.01-.887-.031-1.324-.06-8.683-.615-15.429-5.431-15.429-17.633,0-12.627,16.136-28.56,17.348-29.74l0,0,.07-.068S245.646,738.75,245.646,751.869Z" transform="translate(-191.06 -354.882)" fill="#6c63ff"/>
                        <path id="Path_47" data-name="Path 47" d="M234.563,767.562l6.371-8.9-6.387,9.88-.017,1.022c-.446-.01-.887-.031-1.324-.06l.687-13.126-.005-.1.012-.019.065-1.24-6.4-9.9,6.424,8.974.015.263.519-9.918L229.036,734.2l5.549,8.494.54-20.561,0-.07v.068l-.09,16.214,5.458-6.428-5.48,7.824-.144,8.879,5.1-8.522-5.117,9.828-.081,4.936,7.4-11.861-7.425,13.583Z" transform="translate(-198.032 -354.882)" fill="#3f3d56"/>
                        <path id="Path_48" data-name="Path 48" d="M744.9,354.89c0,102.873-46.708,184.027-104.228,186.267-75.011,2.922-173.046-71.871-104.228-186.267,53.029-88.151,46.664-186.267,104.228-186.267S744.9,252.018,744.9,354.89Z" transform="translate(-247.854 -124.603)" fill="#f2f2f2"/>
                        <rect id="Rectangle_32" data-name="Rectangle 32" width="148.613" height="129.059" transform="translate(262.667 284.977)" fill="#6c63ff"/>
                        <line id="Line_5" data-name="Line 5" x2="562.99" transform="translate(0 414.036)" fill="none" stroke="#3f3d56" stroke-miterlimit="10" stroke-width="2"/>
                        <path id="Path_49" data-name="Path 49" d="M725.568,93.235a14.229,14.229,0,0,0-5.71,27.261,12.6,12.6,0,0,1,.248-3.265c.273-1.156,1.829-7.755,1.829-7.755a5.432,5.432,0,0,1-.457-2.248c0-2.109,1.22-3.684,2.744-3.684a1.9,1.9,0,0,1,1.918,2.134c0,1.3-.832,3.246-1.258,5.05a2.2,2.2,0,0,0,2.249,2.738c2.693,0,4.51-3.462,4.51-7.565,0-3.119-2.1-5.45-5.92-5.45a6.742,6.742,0,0,0-7.006,6.815,4.1,4.1,0,0,0,.94,2.788.7.7,0,0,1,.2.794c-.07.26-.222.889-.292,1.143a.489.489,0,0,1-.711.356c-1.988-.813-2.915-2.985-2.915-5.437,0-4.04,3.411-8.886,10.169-8.886,5.431,0,9.007,3.932,9.007,8.149,0,5.583-3.106,9.75-7.679,9.75a4.083,4.083,0,0,1-3.474-1.772s-.826,3.277-1,3.913a11.824,11.824,0,0,1-1.429,3.049,14.23,14.23,0,1,0,4.04-27.878Z" transform="translate(-353.09 -93.235)" fill="#f2f2f2"/>
                        <path id="Path_50" data-name="Path 50" d="M954.907,267.4a12.433,12.433,0,0,1-3.532.972,6.17,6.17,0,0,0,2.706-3.4,12.218,12.218,0,0,1-3.906,1.493,6.148,6.148,0,0,0-10.639,4.2,6.017,6.017,0,0,0,.159,1.4,17.427,17.427,0,0,1-12.678-6.434,6.162,6.162,0,0,0,1.912,8.219,6.007,6.007,0,0,1-2.795-.769v.076a6.155,6.155,0,0,0,4.937,6.034,6.189,6.189,0,0,1-1.621.216,5.8,5.8,0,0,1-1.156-.114,6.159,6.159,0,0,0,5.748,4.275,12.329,12.329,0,0,1-7.641,2.636,12.468,12.468,0,0,1-1.467-.089,17.189,17.189,0,0,0,9.413,2.782c11.325,0,17.512-9.381,17.512-17.518,0-.267-.006-.534-.019-.794A12.52,12.52,0,0,0,954.907,267.4Z" transform="translate(-419.211 -164.5)" fill="#f2f2f2"/>
                        <path id="Path_51" data-name="Path 51" d="M554.491,124.252h-21.7a1.347,1.347,0,0,0-1.346,1.347v21.7a1.347,1.347,0,0,0,1.347,1.347h10.848v-9.655h-2.915v-3.557h2.915V132.8c0-3.15,2.185-4.865,5-4.865,1.347,0,2.795.1,3.131.146v3.29h-2.242c-1.531,0-1.823.724-1.823,1.791v2.268h3.646l-.476,3.557h-3.169v9.655h6.784a1.347,1.347,0,0,0,1.347-1.347V125.6a1.347,1.347,0,0,0-1.347-1.346Z" transform="translate(-294.07 -106.141)" fill="#f2f2f2"/>
                        <path id="Path_52" data-name="Path 52" d="M563.287,277.479a5.1,5.1,0,0,1,5.081,5.081v10.163a5.1,5.1,0,0,1-5.081,5.081H553.124a5.1,5.1,0,0,1-5.081-5.081V282.561a5.1,5.1,0,0,1,5.081-5.081h10.163m0-2.033H553.124a7.135,7.135,0,0,0-7.114,7.114v10.163a7.135,7.135,0,0,0,7.114,7.114h10.163a7.135,7.135,0,0,0,7.114-7.114V282.561a7.135,7.135,0,0,0-7.114-7.114Z" transform="translate(-298.88 -169.052)" fill="#f2f2f2"/>
                        <path id="Path_53" data-name="Path 53" d="M569.156,285.457a1.521,1.521,0,1,1,1.079-.445A1.524,1.524,0,0,1,569.156,285.457Zm-6.606,1.016a4.065,4.065,0,1,1-4.065,4.065,4.065,4.065,0,0,1,4.065-4.065m0-2.033a6.1,6.1,0,1,0,6.1,6.1,6.1,6.1,0,0,0-6.1-6.1Z" transform="translate(-303.019 -171.948)" fill="#f2f2f2"/>
                        <path id="Path_54" data-name="Path 54" d="M863.587,103.746H843.236a1.989,1.989,0,0,0-2.083,1.9v20.4a2.146,2.146,0,0,0,2.083,2.09h20.345a2.037,2.037,0,0,0,1.956-2.09v-20.4a1.87,1.87,0,0,0-1.95-1.9Zm-14.876,20.325h-3.493V113.21h3.493Zm-1.626-12.513h-.025a1.9,1.9,0,1,1,.025,0Zm14.393,12.513h-3.493v-5.939c0-1.423-.508-2.395-1.772-2.395a1.911,1.911,0,0,0-1.791,1.289,2.352,2.352,0,0,0-.121.857v6.187h-3.493V113.21H854.3v1.512a3.536,3.536,0,0,1,3.15-1.766c2.293,0,4.027,1.512,4.027,4.77Z" transform="translate(-396.369 -97.608)" fill="#f2f2f2"/>
                        <path id="Path_55" data-name="Path 55" d="M707.563,361.354s24.726.672,28.81,2.158,5.91,12.11,5.91,12.11l-19.2,6.034-14.359-.813-10.807.764,6.034-15.8Z" transform="translate(-347.071 -204.797)" fill="#2f2e41"/>
                        <path id="Path_56" data-name="Path 56" d="M714.526,347.458s1.627,6.283-1.984,10.732,15.522,20.3,15.522,20.3l10.093-1.735s-2.225-19.306.764-24.2S714.526,347.458,714.526,347.458Z" transform="translate(-353.475 -199.015)" fill="#9f616a"/>
                        <path id="Path_57" data-name="Path 57" d="M714.526,347.458s1.627,6.283-1.984,10.732,15.522,20.3,15.522,20.3l10.093-1.735s-2.225-19.306.764-24.2S714.526,347.458,714.526,347.458Z" transform="translate(-353.475 -199.015)" opacity="0.1"/>
                        <path id="Path_58" data-name="Path 58" d="M638.361,568.843l-22.269,29.532,15.2,8.873,20.917-34.222Z" transform="translate(-202.147 -236.69)" fill="#9f616a"/>
                        <path id="Path_59" data-name="Path 59" d="M797.078,708.012s-8.2-9.529-7.835-7.4-5.835,20.145-.066,21.888,55.238,6.366,55.919.78-14.758-9.5-14.758-9.5-9.172-8.815-11.205-7.918S797.078,708.012,797.078,708.012Z" transform="translate(-374.3 -345.828)" fill="#2f2e41"/>
                        <path id="Path_60" data-name="Path 60" d="M645.673,615.519l17.788,30.3,13.43-7.777-17.937-24.81Z" transform="translate(-212.454 -255.161)" fill="#9f616a"/>
                        <path id="Path_61" data-name="Path 61" d="M850.82,585.413l-5.279,7.471s-25.166,34.953-22.494,37.775,13.8,13.488,19.464,11.421,25.772-37.791,25.772-37.791l-5.246-20.975Z" transform="translate(-376.735 -297.151)" fill="#2f2e41"/>
                        <path id="Path_62" data-name="Path 62" d="M684.356,545.775s5.636,10.516,19.921,14.077,29.209,10.84,29.74,10.749,2.034-.9,2.4,1.228,4.283,5.827,4.283,5.827,15.779,53.62,21.8,53.678,18.41-4.258,18.667-5.943-18.452-56.442-18.634-57.5l-2.191-12.749s24.743,3.951,27.051,4.648,22.834,11.936,22.834,11.936,20.925-15.082,1.353-30.313-53.213-26.4-53.213-26.4L746.3,511.61l-10.518-.628-1.549-5.939-24.911,1.437-26,11.031Z" transform="translate(-318.681 -264.584)" fill="#2f2e41"/>
                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="19.404" cy="19.404" r="19.404" transform="translate(357.166 122.142)" fill="#9f616a"/>
                        <path id="Path_63" data-name="Path 63" d="M702.8,362.07l2.334-.4s4.215,16.23,17.255,18.911c0,0,6.434-7.708,6.16-9.008s5.1-8.24,7.951-4.355-.7,72.312,1.7,73.541,7.545,2.532,3.4,7.072-20.593,10.649-24.237,8.541-11.089-19.97-14.31-25.98-8.375-26.453-8.375-26.453-6.582-22.386-2.532-27.457S702.8,362.07,702.8,362.07Z" transform="translate(-343.929 -204.928)" fill="#d0cde1"/>
                        <path id="Path_64" data-name="Path 64" d="M709.618,444.627A110.678,110.678,0,0,1,707.4,458.8c-.952,3.8-2.06,6.094-3.324,6-3.226-.241-4.693,4.491-5.449,9.947-.808,5.788-.808,12.391-1.251,14.724-.587,3.083-4.341,5.436-11.843,4.745a52.936,52.936,0,0,1-13.049-3.2c-10.129-3.683-9.829-33.549-8.135-55.71,1.036-13.584,2.594-24.28,2.594-24.28a120.6,120.6,0,0,1-4.061-15.467,20.562,20.562,0,0,1-.293-3.474c.15-5.495,10.181-20.343,16.243-29.592s14.346-2.464,14.346-2.464l-2.881,8.7s4.465,6.89,4.5,13.447,10.69,11.289,14.516,14.464C711.711,398.623,711.652,425.249,709.618,444.627Z" transform="translate(-335.127 -203.244)" fill="#2f2e41"/>
                        <path id="Path_65" data-name="Path 65" d="M756.7,367.054s.7.827,3.9.423c3.84-.484,9.12.845,10.346,4.511,2.249,6.723,8.591,34.073,8.591,34.073s2.64,31.267,3.826,38.173.6,13.023,1.768,16.65-11.554-6.765-16.244-5.412-7.7-16.177-7.113-22.295,1.087-12.766-.54-19.049,1.257-22.913,1.15-26.723S752.044,373.994,756.7,367.054Z" transform="translate(-367.502 -207.168)" fill="#2f2e41"/>
                        <path id="Path_66" data-name="Path 66" d="M777.537,533.479s20.545,11.209,22.512,27.162-27.48-15.447-27.48-15.447Z" transform="translate(-373.365 -276.416)" fill="#9f616a"/>
                        <path id="Path_67" data-name="Path 67" d="M801.164,512.8s6.3,31.733,16.1,30.047-5.478-31.873-5.478-31.873Z" transform="translate(-383.724 -267.053)" fill="#9f616a"/>
                        <path id="Path_68" data-name="Path 68" d="M852.386,747.762s-5.022,5.785-6.267,4.905-4.109,11.1-1.527,13.388,12.525,6.051,12.633,9.861,26.777,3.054,27.731-.938-3.345-13.1-6.366-14.766S864.4,743.189,864.4,743.189,855.648,744.467,852.386,747.762Z" transform="translate(-385.348 -363.674)" fill="#2f2e41"/>
                        <path id="Path_69" data-name="Path 69" d="M728.71,504.789c-4.765-3.363-18.068-4.2-29.879-4.973-5.182-.332-10.077-.658-13.838-1.186-3.624-.5-6.883-3.813-9.777-8.747-4.387-7.476-7.933-18.687-10.664-29.508,1.036-13.584,2.594-24.28,2.594-24.28a120.591,120.591,0,0,1-4.061-15.468,8.277,8.277,0,0,1,2.62-2.36c12.528-5.377,24.489,47.543,26.007,55.847.026.137.046.267.065.378.737,4.276,8.7,7.933,15.826,9.38,4.393.893,8.48.952,10.364-.209,4.947-3.037,20.161,9.119,21.314,9.464S735.554,509.632,728.71,504.789Z" transform="translate(-332.361 -228.318)" opacity="0.1"/>
                        <path id="Path_70" data-name="Path 70" d="M663.216,412.688s-9.105,4.3-5.1,24.394,12.06,54.259,24.386,55.969,36.87,1.32,43.717,6.159,11.728-11.313,10.575-11.662-16.368-12.5-21.315-9.462-25-2.266-26.187-9.172S675.948,407.218,663.216,412.688Z" transform="translate(-330.056 -225.997)" fill="#2f2e41"/>
                        <path id="Path_71" data-name="Path 71" d="M779.946,396.457l10.007,23.22s1.007-.5,2.178,3.125,1.718,6.815,3.768,9.2,11.172,36.363,11.446,37.957-12.6,7.088-15.475,2.66S779.946,396.457,779.946,396.457Z" transform="translate(-375.817 -219.403)" fill="#2f2e41"/>
                        <path id="Path_72" data-name="Path 72" d="M713.7,316.8c.744-.2.853-1.186.878-1.955.133-4.12,2.614-8.347,6.558-9.546a11.024,11.024,0,0,1,4.647-.256,17.6,17.6,0,0,1,6.294,2.1,9.817,9.817,0,0,0,3.2,1.449c.769.116,4.1,1.085,4.867,1.221,1.684.3,3.25,1.807,4.866,1.246,1.546-.536,1.889-2.539,1.926-4.175.085-3.728-2.745-8.888-5.238-11.661-1.892-2.1-4.74-3.076-7.524-3.581a50.1,50.1,0,0,0-9.84-.6c-4.456.079-9.035.423-13.053,2.351s-7.393,5.765-7.586,10.218c-.04.927.056,1.859-.022,2.784-.19,2.257-1.389,4.3-1.951,6.489a11.874,11.874,0,0,0,1.658,9.489c1.491,2.194,3.835,4.179,3.749,6.831l3.233-3.35a2.655,2.655,0,0,0,.79-3.244l-1.145-4.464a4.028,4.028,0,0,1-.1-2.484C711.133,312.634,712.46,317.134,713.7,316.8Z" transform="translate(-348.071 -175.527)" fill="#2f2e41"/>
                        <path id="Path_73" data-name="Path 73" d="M352.5,462c0,61.865-36.778,83.465-82.145,83.465s-82.145-21.6-82.145-83.465,82.145-140.566,82.145-140.566S352.5,400.138,352.5,462Z" transform="translate(-181.659 -188.188)" fill="#f2f2f2"/>
                        <path id="Path_74" data-name="Path 74" d="M123.25,442.77,124.092,391,159.1,326.942l-34.88,55.931.378-23.281,24.131-46.341L124.7,353.432h0l.68-41.87,25.839-36.894-25.732,30.31.425-76.775-2.671,101.637.219-4.192L97.192,285.434l25.85,48.261-2.448,46.762-.073-1.241L90.235,336.9l30.194,46.7-.306,5.848-.055.088.025.48-6.21,118.64h8.3l1-61.28,30.12-46.587Z" transform="translate(-37.546 -94.953)" fill="#3f3d56"/>
                    </g>
                    </svg>
                    <button className="text-xs md:text-base absolute -bottom-6 bg-gradient-to-t from-violate-default via-secondary-vio to-violate-default px-6 py-1 rounded-2xl">                        
                        <Link to="/service" onClick={toggleHome}>Digital Marketing</Link>
                    </button>
                </div>
            </div>           
        </div>
    )
}

export default ServiceHome
