import React from 'react'
import AboutHeader from '../Componentes/About/AboutHeader'
import Circle from '../Componentes/About/Circle'
import OurTeam from '../Componentes/About/OutTeam/OurTeam'
import Border from '../Componentes/Common/Border'
import {Helmet} from "react-helmet";

function About() {
    return (
        <div>
            <Helmet>
                <title>Siliguri Sutra -About</title>
                <link rel="shortcut icon" href="./favicon.ico" type="image/x-icon"></link>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                name="description"
                content="We are Siliguri Sutra & Siliguri Sutra made a Best Marketing comapny with some members, who always priotised their work and they are very experienced of their work. They also believe in good relationship with all of our clients. " 
                />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://siligurisutra.com"/>
                <meta property="og:title" content="Siliguri Sutra - Photography & Digital Marketing"/>
                <meta property="og:description" content="We are Siliguri Sutra & Siliguri Sutra  made a Best Marketing comapny with some members, who always priotised their work and they are very experienced of their work. They also believe in good relationship with all of our clients. " />
                <meta property="og:image" content="./siliguri_sutra.svg"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://siligurisutra.com"/>
                <meta property="twitter:title" content="Siliguri Sutra - Photography & Digital Marketing"/>
                <meta property="twitter:description" content="We are Siliguri Sutra & Siliguri Sutra made a Best Marketing comapny with some members, who always priotised their work and they are very experienced of their work. They also believe in good relationship with all of our clients."/>
                <meta property="twitter:image" content="./siliguri_sutra.svg"/>
                <meta name="next-head-count" content="14"/>
            </Helmet>
            <Circle/>                     
            <AboutHeader/>
            <Border/>
            <OurTeam/>
            <Border/>
        </div>
    )
}

export default About
