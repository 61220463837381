import React from 'react'

function Border() {
    return (
        <div className="flex justify-center pt-2 pb-4">
            <div className="border-b-4 border-lightskyblue-default w-28 shadow-green rounded-lg z-20"></div>            
        </div>
    )
}

export default Border
