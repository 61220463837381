import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

function ServiceDesign(props) {  
    return (
        <div className="flex justify-center ">            
            {props.no % 2 === 0 ?
                <div data-aos="fade-down-right" className='w-full flex items-center md:w-11/12 md:pb-10 mb-4 text-white rounded-xl'>
                    <div className='w-full md:py-4 text-center px-4'>
                        <p className="font-sans font-bold text-xs md:text-xl mb-1 md:mb-2">{props.service}</p>
                        <h3 className=' text-xxs md:text-sm pb-2 h-6 md:h-full overflow-hidden'>{props.des}</h3>                
                    </div> 

                    <div className="flex justify-center w-6/12">
                        <img src={props.imageUrl} className="w-full h-full md:w-72 md:h-72" alt="centent_pic"/>
                    </div>
                </div>   
            :  
                <div data-aos="fade-down-left" className='w-full flex items-center md:w-11/12 md:pb-10 mb-4 text-white rounded-xl'>
                    <div className="flex justify-center w-6/12">
                        <img src={props.imageUrl} className="w-full h-full md:w-72 md:h-72" alt="content_pic"/>
                    </div>

                    <div className='w-full md:py-4 text-center px-4'>
                        <p className="font-sans font-bold text-xs md:text-xl mb-1 md:mb-2">{props.service}</p>
                        <h3 className=' text-xxs md:text-sm pb-2 h-6 md:h-full overflow-hidden '>{props.des}</h3>                
                    </div>
                </div>     
            }           
        </div>    
    )
}

export default ServiceDesign;