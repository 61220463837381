import React from 'react';
import business from "../Images/train.png"

function Header() {
    return (
        <div className="text-white pt-20 px-9">
            <div>
                <p className="italic text-sm md:text-base text-center md:px-20 pt-4">
                Siliguri Sutra is a leading community in West Bengal, Siliguri & Kolkata being its prime locations. Being a Community
                it also has 3 ventures/Brands of its own in different fields. We have around 100+ members across West Bengal.
                Our Aim & Moto is provide a Global level platform to all Young & talented hustlers in the field of Photography and
                also represent our City Siliguri at a global level. In the past 1.5 years we have conducted several contests in order to
                promote the young and talented photographers and we have also indulged ourselves in social work. In upcoming 
                times we are aiming to do things at a more greater level in order to promote the talented peoples of our city and 
                our city itself.        
                </p>
            </div>
            <div className="pt-9 pb-4 md:px-20">
                <img src={business} alt="business_image" className="w-full"/>
            </div>
        </div>
    )
}

export default Header
